import React from 'react'
import splendour from '../../images/splendour.jpeg'
import home from '../../images/home.png'
import desk from '../../images/desk.png'
import download from '../../images/download.jpg'
import shree from '../../images/shree.jpeg'





function Allinone() {
  return (
    <div className='section3'>
      <div className='allinone'>
        <section className="px-2 py-7 bg-white md:px-0">
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div className="flex flex-wrap items-center sm:-mx-3">
              <div className="w-full md:w-1/2 md:px-3">
                <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                  <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                    <span className="block xl:inline">Welcome to</span>
                    <span className="block  xl:inline">Shivangi Enterprises.</span>
                  </h1>
                  <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">Invest in safety & quality furniture that supports your success and creates a safer, comfortable, productive & protective environment.</p>
                  <div className="relative flex flex-col sm:flex-row sm:space-x-4">
                    <a href="/safety" className="flex items-center px-6 py-3 text-white bg-black rounded-md hover:bg-indigo-700 hover:text-white">
                      Safety
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                    </a>
                    <a href="/furniture" className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-black rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
                      Furniture
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="w-full overflow-hidden rounded-md shadow-xl sm:rounded-xl">
                  <div className="overflow-hidden relative h-56 rounded-lg sm:h-64 xl:h-80 2xl:h-96">
                    {/* Item 1 */}
                    <img src={home} className="block absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2" alt="..." />
                    {/* Item 2 */}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <div className="box-border mt-20 mb-20 flex flex-col px-8 items-center mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-9xl lg:px-16">

      {/* Image */}
      <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
        <img src={desk} alt="Shivangi Enterprises" width={590} height={590} />
      </div>

      {/* Content */}
      <div className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
        <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
          Why us?
        </h2>
        <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
          We take pride in offering only the highest quality products to our customers.
        </p>
        <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
          <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
            <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-black rounded-full"><span className="text-sm font-bold">✓</span></span>We offer reliable and innovative safety device solutions.
          </li>
          <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
            <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-black rounded-full"><span className="text-sm font-bold">✓</span></span>Your safety is our first priority.
          </li>
          <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
            <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-black rounded-full"><span className="text-sm font-bold">✓</span></span>We offer a wide variety of furniture styles, colors, and designs.
          </li>
          <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
            <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-black rounded-full"><span className="text-sm font-bold">✓</span></span>We offer competitive pricing and excellent value for money.
          </li>
        </ul>
      </div>
      {/* End Content */}
    </div>



    <div class="py-20 bg-gray-50">
      <div class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
        <div class="flex flex-wrap items-center -mx-3">
          <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
            <div class="w-full lg:max-w-md">
              <h2 class="mb-4 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading">Safety and comfort in one place - our quality safety devices and furniture!</h2>
              <p class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">Our commitment to superior quality extends to both safety devices and furniture, with products designed to withstand heavy use:</p>
              <ul>
                <li class="flex items-center py-2 space-x-4 xl:py-3">
                  <svg class="w-8 h-8 text-pink-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.53 98.68" id="furniture"><path d="M64.31 67.27a1.5 1.5 0 0 0 1.5-1.5c0-19.48-12.27-32.24-31.4-32.87V1.5a1.5 1.5 0 1 0-3 0v31.4C12.27 33.53 0 46.29 0 65.77a1.5 1.5 0 0 0 1.5 1.5h19.55A12.33 12.33 0 0 0 32.9 77.74a12.33 12.33 0 0 0 11.86-10.47zM32.9 35.87c14.12 0 29.19 7.5 29.88 28.4H3c.71-20.9 15.78-28.4 29.9-28.4zm0 38.87a9.36 9.36 0 0 1-8.81-7.47h17.63a9.36 9.36 0 0 1-8.82 7.47zm33.04 10.78-9.31-7.1a1.5 1.5 0 0 0-1.82 2.39l9.31 7.1a1.5 1.5 0 0 0 1.82-2.39zM11.61 78.3l-9.05 7.42A1.5 1.5 0 1 0 4.46 88l9.06-7.41a1.5 1.5 0 1 0-1.9-2.32zm21.73 6.91a1.5 1.5 0 0 0-1.5 1.5v10.47a1.5 1.5 0 1 0 3 0V86.71a1.5 1.5 0 0 0-1.5-1.5z" data-name="Layer 2"></path></svg>
                  <span class="font-medium text-gray-500">"Our innovative and reliable safety devices ensure your security"</span>
                </li>
                <li class="flex items-center py-2 space-x-4 xl:py-3">
                  <svg class="w-8 h-8 text-pink-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.53 98.68" id="furniture"><path d="M64.31 67.27a1.5 1.5 0 0 0 1.5-1.5c0-19.48-12.27-32.24-31.4-32.87V1.5a1.5 1.5 0 1 0-3 0v31.4C12.27 33.53 0 46.29 0 65.77a1.5 1.5 0 0 0 1.5 1.5h19.55A12.33 12.33 0 0 0 32.9 77.74a12.33 12.33 0 0 0 11.86-10.47zM32.9 35.87c14.12 0 29.19 7.5 29.88 28.4H3c.71-20.9 15.78-28.4 29.9-28.4zm0 38.87a9.36 9.36 0 0 1-8.81-7.47h17.63a9.36 9.36 0 0 1-8.82 7.47zm33.04 10.78-9.31-7.1a1.5 1.5 0 0 0-1.82 2.39l9.31 7.1a1.5 1.5 0 0 0 1.82-2.39zM11.61 78.3l-9.05 7.42A1.5 1.5 0 1 0 4.46 88l9.06-7.41a1.5 1.5 0 1 0-1.9-2.32zm21.73 6.91a1.5 1.5 0 0 0-1.5 1.5v10.47a1.5 1.5 0 1 0 3 0V86.71a1.5 1.5 0 0 0-1.5-1.5z" data-name="Layer 2"></path></svg>
                  <span class="font-medium text-gray-500">"Furniture that tells a story"</span>
                </li>
                <li class="flex items-center py-2 space-x-4 xl:py-3">
                  <svg class="w-8 h-8 text-pink-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.53 98.68" id="furniture"><path d="M64.31 67.27a1.5 1.5 0 0 0 1.5-1.5c0-19.48-12.27-32.24-31.4-32.87V1.5a1.5 1.5 0 1 0-3 0v31.4C12.27 33.53 0 46.29 0 65.77a1.5 1.5 0 0 0 1.5 1.5h19.55A12.33 12.33 0 0 0 32.9 77.74a12.33 12.33 0 0 0 11.86-10.47zM32.9 35.87c14.12 0 29.19 7.5 29.88 28.4H3c.71-20.9 15.78-28.4 29.9-28.4zm0 38.87a9.36 9.36 0 0 1-8.81-7.47h17.63a9.36 9.36 0 0 1-8.82 7.47zm33.04 10.78-9.31-7.1a1.5 1.5 0 0 0-1.82 2.39l9.31 7.1a1.5 1.5 0 0 0 1.82-2.39zM11.61 78.3l-9.05 7.42A1.5 1.5 0 1 0 4.46 88l9.06-7.41a1.5 1.5 0 1 0-1.9-2.32zm21.73 6.91a1.5 1.5 0 0 0-1.5 1.5v10.47a1.5 1.5 0 1 0 3 0V86.71a1.5 1.5 0 0 0-1.5-1.5z" data-name="Layer 2"></path></svg>
                  <span class="font-medium text-gray-500">"Out of the Design and finishing"</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0"><img class="mx-auto sm:max-w-sm lg:max-w-full" src="https://cdn.devdojo.com/images/november2020/feature-graphic.png" alt="feature image"></img></div>
        </div>
      </div>
</div>


<section class="flex items-center justify-center py-20 bg-white min-w-screen">
  <div class="px-16 bg-white">
    <div class="container flex flex-col items-start mx-auto lg:items-center">
      <p class="relative flex items-start justify-start w-full text-lg font-bold tracking-wider uppercase lg:justify-center lg:items-center">Don't just take our word for it</p>
      <h2 class="relative flex items-start justify-start w-full max-w-3xl text-5xl font-bold lg:justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="absolute right-0 hidden w-12 h-12 -mt-2 -mr-16 text-gray-200 lg:inline-block" viewBox="0 0 975.036 975.036">
          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
        </svg>
        See what others are saying
      </h2>
      <div class="block w-full h-0.5 max-w-lg mt-6 bg-purple-100 rounded-full"></div>
      <div class="items-center justify-center w-full mt-12 mb-4 lg:flex">
        <div class="flex flex-col items-start justify-start w-full h-auto mb-12 lg:w-1/3 lg:mb-0">
          <div class="flex items-center justify-center">
            <div class="w-16 h-16 mr-4 overflow-hidden bg-gray-200 rounded-full">
              <img src={shree} class="object-cover w-full h-full"></img>
            </div>
            <div class="flex flex-col items-start justify-center">
              <h4 class="font-bold text-gray-800">Shree Cement</h4>
              <p class="text-gray-600">Customer</p>
            </div>
          </div>
          <blockquote class="mt-8 text-lg text-gray-500">"Shivangi Enterprises provided us with exceptional service when it came to selecting and installing safety devices for our commercial space."</blockquote>
        </div>
        <div class="flex flex-col items-start justify-start w-full h-auto px-0 mx-0 mb-12 border-l border-r border-transparent lg:w-1/3 lg:mb-0 lg:px-8 lg:mx-8 lg:border-gray-200">
          <div class="flex items-center justify-center">
            <div class="w-16 h-16 mr-4 overflow-hidden bg-gray-200 rounded-full">
              <img src={download} class="object-cover w-full h-full"></img>
            </div>
            <div class="flex flex-col items-start justify-center">
              <h4 class="font-bold text-gray-800">Sri Chaitanya School</h4>
              <p class="text-gray-600">Customer</p>
            </div>
          </div>
          <blockquote class="mt-8 text-lg text-gray-500">"The chairs and tables are beautifully designed and made with high-quality materials. They've held up to daily use from our students and still look brand new. Thanks for making such a great product."</blockquote>
        </div>
        <div class="flex flex-col items-start justify-start w-full h-auto lg:w-1/3">
          <div class="flex items-center justify-center">
            <div class="w-16 h-16 mr-4 overflow-hidden bg-gray-200 rounded-full">
              <img src={splendour} class="object-cover w-full h-full"></img>
            </div>
            <div class="flex flex-col items-start justify-center">
              <h4 class="font-bold text-gray-800">Splendor landbase Ltd</h4>
              <p class="text-gray-600">Customer</p>
            </div>
          </div>
          <blockquote class="mt-8 text-lg text-gray-500">"Thanks to Shivangi Enterprises, we now feel much safer in our home. They provided us with top-notch safety devices that have given us peace of mind knowing that we're protected in case of an emergency."</blockquote>
        </div>
      </div>
    </div>
  </div>
</section>

{/* <section class="box-border py-8 leading-7 text-gray-900 bg-white border-0 border-gray-200 border-solid sm:py-12 md:py-16 lg:py-24">
    <div class="box-border max-w-6xl px-4 pb-12 mx-auto border-solid sm:px-6 md:px-6 lg:px-4">
      <div class="flex flex-col items-center leading-7 text-center text-gray-900">
        <h2 class="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid sm:text-4xl md:text-5xl">
          Our Categories
        </h2>
        <p class="box-border mt-4 text-2xl leading-normal text-gray-900 border-solid">
          We've got a bunch of categories for you!
        </p>
      </div>
      <div class="text-center mb-20">
        <div class="flex mt-6 justify-center">
          <div class="w-16 h-1 rounded-full bg-black inline-flex"></div>
        </div>
      </div>
      <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
        <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
          <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  mb-5 flex-shrink-0">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
              <metadata>
                Created by potrace 1.10, written by Peter Selinger 2001-2011
              </metadata>
              <g transform="translate(0.000000,300.000000) scale(0.050000,-0.050000)" fill="#000000" stroke="none">
                <path d="M3622 5967 c-25 -18 -59 -63 -74 -100 l-29 -67 -397 0 c-551 0 -729 -62 -914 -320 -65 -91 -74 -97 -123 -78 -152 58 -181 22 -509 -622 -1076 -2109 -1081 -2119 -1069 -2195 18 -111 47 -129 593 -345 655 -260 670 -258 771 91 28 98 83 285 120 414 38 129 101 343 139 475 39 132 101 346 139 475 37 129 110 379 161 555 51 176 124 426 162 555 75 257 74 304 -8 386 -133 132 38 197 541 206 l395 6 0 37 c0 40 -21 85 -49 105 -35 24 -93 30 -131 14z m99 -320 c10 -8 22 -28 27 -44 10 -32 9 -47 -8 -87 -29 -85 -40 -162 -28 -208 6 -23 19 -38 38 -46 25 -11 37 -10 85 5 92 32 153 96 186 189 21 60 21 67 7 117 -19 62 -73 145 -118 182 -37 32 -108 42 -179 24 l-47 -11 -8 50 c-9 60 -8 160 2 198 6 20 10 45 10 57 0 35 -21 72 -56 102 l-28 25 31 7 c34 9 79 -11 94 -38z"/>
              </g>
            </svg>
          </div>
          <p class="text-xl font-semibold">Safety Item</p>
        </div>
        <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
          <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  mb-5 flex-shrink-0">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
              <metadata>
                Created by potrace 1.10, written by Peter Selinger 2001-2011
              </metadata>
              <g transform="translate(0.000000,300.000000) scale(0.050000,-0.050000)" fill="#000000" stroke="none">
                <path d="M3622 5967 c-25 -18 -59 -63 -74 -100 l-29 -67 -397 0 c-551 0 -729 -62 -914 -320 -65 -91 -74 -97 -123 -78 -152 58 -181 22 -509 -622 -1076 -2109 -1081 -2119 -1069 -2195 18 -111 47 -129 593 -345 655 -260 670 -258 771 91 28 98 83 285 120 414 38 129 101 343 139 475 39 132 101 346 139 475 37 129 110 379 161 555 51 176 124 426 162 555 75 257 74 304 -8 386 -133 132 38 197 541 206 l395 6 0 37 c0 40 -21 85 -49 105 -35 24 -93 30 -131 14z m99 -320 c10 -8 22 -28 27 -44 10 -32 9 -47 -8 -87 -29 -85 -40 -162 -28 -208 6 -23 19 -38 38 -46 25 -11 37 -10 85 5 92 32 153 96 186 189 21 60 21 67 7 117 -19 62 -73 145 -118 182 -37 32 -108 42 -179 24 l-47 -11 -8 50 c-9 60 -8 160 2 198 6 20 10 45 10 57 0 35 -21 72 -56 102 l-28 25 31 7 c34 9 79 -11 94 -38z"/>
              </g>
            </svg>
          </div>
          <p class="text-xl font-semibold">Electronics</p>
        </div>
        <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
          <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  mb-5 flex-shrink-0">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
              <metadata>
                Created by potrace 1.10, written by Peter Selinger 2001-2011
              </metadata>
              <g transform="translate(0.000000,300.000000) scale(0.050000,-0.050000)" fill="#000000" stroke="none">
              <path d="M910 7979 c-193 -55 -333 -144 -450 -289 -68 -84 -113 -167 -148
            -276 -42 -129 -42 -120 -42 -2362 0 -2281 -1 -2232 44 -2375 73 -231 285 -446
            526 -531 126 -45 143 -46 1350 -46 630 0 1151 -4 1158 -9 10 -6 13 -66 13
            -271 0 -145 -4 -266 -8 -270 -4 -5 -48 -10 -98 -13 -49 -3 -108 -10 -129 -17
            -56 -17 -151 -114 -167 -171 -16 -55 -17 -132 -1 -185 16 -54 83 -129 143
            -159 l48 -25 1341 0 1341 0 47 24 c80 41 130 106 151 197 24 99 -13 198 -100
            273 -53 45 -90 57 -204 63 -49 3 -94 8 -98 13 -4 4 -8 125 -8 270 0 205 3 265
            13 271 7 5 528 9 1158 9 1207 0 1224 1 1350 46 116 41 229 113 320 204 134
            134 207 280 239 481 16 96 15 4351 0 4451 -37 234 -145 411 -345 566 -72 56
            -165 98 -289 132 -69 19 -139 20 -3580 19 -3386 -1 -3512 -2 -3575 -20z m7090
            -573 c60 -40 98 -81 123 -135 l22 -46 0 -1715 0 -1715 -3655 0 -3655 0 -3
            1702 c-2 1697 -2 1702 18 1754 12 29 30 65 41 81 26 36 103 89 143 99 17 4
            1582 6 3477 6 l3445 -2 44 -29z m138 -4188 c8 -8 12 -57 12 -157 0 -188 -13
            -234 -90 -311 -100 -101 312 -90 -3570 -90 -3882 0 -3469 -11 -3570 90 -77 77
            -90 123 -90 311 0 100 4 149 12 157 17 17 7279 17 7296 0z m-3097 -1128 c18
            -10 19 -24 19 -268 0 -187 -3 -261 -12 -270 -17 -17 -1099 -17 -1116 0 -9 9
            -12 83 -12 270 0 234 2 258 18 267 24 14 1076 15 1103 1z"/>
            <path d="M3821 7141 c-57 -23 -125 -90 -151 -148 -27 -61 -27 -162 1 -222 11
            -25 42 -68 69 -97 l50 -53 0 -463 c0 -473 -2 -496 -39 -566 -15 -26 -71 -151
            -71 -157 0 -2 -17 -42 -39 -87 -21 -46 -57 -128 -81 -182 -23 -54 -45 -106
            -50 -115 -8 -17 -22 -48 -65 -146 -36 -81 -57 -127 -94 -208 -17 -37 -31 -69
            -31 -72 0 -3 -14 -35 -31 -72 -16 -38 -37 -83 -45 -101 -8 -18 -14 -63 -14
            -101 0 -85 26 -148 84 -205 84 -81 11 -76 1176 -76 1165 0 1092 -5 1176 76 58
            57 84 120 84 205 0 38 -6 83 -14 101 -8 18 -29 63 -45 101 -17 37 -31 69 -31
            72 0 3 -14 35 -31 72 -36 77 -72 157 -101 223 -37 85 -50 115 -58 131 -5 9
            -27 61 -50 115 -24 54 -60 136 -81 182 -22 45 -39 85 -39 87 0 6 -56 131 -71
            157 -37 70 -39 93 -39 565 l0 463 51 56 c68 75 89 123 89 205 0 84 -25 143
            -85 201 -85 80 -51 77 -755 77 -576 0 -628 -2 -669 -18z m786 -547 l22 -6 3
            -527 3 -526 33 -70 c18 -38 39 -83 47 -100 7 -16 27 -60 44 -98 17 -37 31 -69
            31 -72 0 -2 17 -42 39 -87 21 -46 58 -128 81 -183 23 -55 45 -104 49 -110 20
            -28 72 -165 66 -174 -4 -8 -170 -11 -535 -11 -365 0 -531 3 -535 11 -6 9 46
            146 66 174 4 6 26 55 49 110 23 55 60 137 81 183 22 45 39 85 39 87 0 3 14 35
            31 72 17 38 37 82 44 98 8 17 29 62 47 100 l33 70 3 527 c3 486 4 527 20 531
            26 7 214 8 239 1z"/>
              </g>
            </svg>
          </div>
          <p class="text-xl font-semibold">Clothing</p>
        </div>
      </div>
    </div>
  </section> */}

<section className="box-border py-8 leading-7 text-gray-900 bg-white border-0 border-gray-200 border-solid sm:py-12 md:py-16 lg:py-24">
      <div className="box-border max-w-6xl px-4 pb-12 mx-auto border-solid sm:px-6 md:px-6 lg:px-4">
        <div className="flex flex-col items-center leading-7 text-center text-gray-900">
          <h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid sm:text-4xl md:text-5xl">
            Our Categories
          </h2>
          <p className="box-border mt-4 text-2xl leading-normal text-gray-900 border-solid">
            We've got a bunch of categories for you!
          </p>
        </div>
        <div className="text-center mb-20">
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-black inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full mb-5 flex-shrink-0">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10"
                viewBox="0 0 300.000000 300.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0.000000,300.000000) scale(0.050000,-0.050000)" fill="#000000" stroke="none">
                  <path d="M3622 5967 c-25 -18 -59 -63 -74 -100 l-29 -67 -397 0 c-551 0 -729
            -62 -914 -320 -65 -91 -74 -97 -123 -78 -152 58 -181 22 -509 -622 -1076
            -2109 -1081 -2119 -1069 -2195 18 -111 47 -129 593 -345 655 -260 670 -258
            771 91 28 98 83 285 120 414 38 129 101 343 139 475 39 132 101 346 139 475
            37 129 110 379 161 555 51 176 124 426 162 555 75 257 74 304 -8 386 -133 132
            38 197 541 206 l395 6 0 -140 0 -140 -105 -34 c-372 -120 -658 -426 -745 -794
            -25 -105 -29 -382 -30 -1700 l0 -1575 1090 0 1090 0 0 1555 c-1 1271 -6 1580
            -29 1690 -82 384 -373 708 -735 818 l-136 41 0 128 c0 70 7 128 15 128 8 0
            307 -41 664 -90 778 -108 767 -107 843 -32 97 98 43 320 -79 323 -22 0 -14 11
            22 30 148 81 146 306 -3 368 -57 24 -627 -41 -1234 -139 -191 -31 -222 -27
            -236 31 -27 104 -197 161 -290 96z" />
            <path d="M2640 461 c0 -500 -92 -461 1098 -461 l941 0 66 58 65 59 6 321 7
            322 -1092 0 -1091 0 0 -299z"/>
                </g>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Safety Item</h2>
              <p className="leading-relaxed text-base">
                Your safety is our top priority. That's why we offer the highest quality safety equipment to ensure you have the protection you need. From head to toe, we have you covered with our comprehensive range of safety gear.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-black-200 text-indigo-500 mb-5 flex-shrink-0">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 900.000000 900.000000"
                className="w-10 h-10"
                preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M3853 8988 c1 -8 -20 -17 -53 -24 -238 -49 -488 -239 -608 -464 -28
            -52 -73 -165 -89 -224 -15 -55 -17 -167 -22 -1081 l-6 -1020 -935 -5 c-760 -4
            -942 -8 -970 -19 -182 -71 -286 -166 -348 -316 -74 -181 -28 -416 108 -555
            119 -122 207 -158 411 -168 l144 -7 5 -1060 c4 -817 8 -1066 17 -1088 7 -16
            13 -40 13 -54 0 -41 61 -212 110 -308 23 -44 102 -161 154 -226 127 -160 362
            -315 581 -383 138 -43 180 -48 445 -54 l265 -7 5 -910 5 -910 23 -32 c40 -57
            73 -73 145 -73 75 0 118 23 151 80 20 34 21 53 26 585 l5 550 1850 0 1850 0 5
            -550 c3 -382 9 -558 16 -575 27 -59 83 -90 162 -90 71 0 115 25 149 85 l23 40
            0 1247 c0 686 4 1249 8 1252 5 3 58 7 118 10 202 9 306 49 420 161 124 122
            177 279 150 448 -16 98 -29 134 -76 207 -44 69 -91 116 -160 160 -126 81 -186
            89 -721 89 -316 1 -428 4 -437 13 -9 9 -12 103 -12 360 l0 348 23 14 c12 8 36
            18 52 22 82 19 252 121 339 203 88 84 142 159 200 276 43 89 53 119 77 229 18
            85 19 148 19 1535 -1 1380 -2 1450 -20 1536 -21 104 -39 156 -82 240 -122 243
            -362 432 -618 489 -33 7 -54 17 -52 24 1 9 -311 12 -1433 12 -1122 0 -1434 -3
            -1432 -12z m2872 -375 c196 -69 316 -192 392 -398 17 -48 18 -120 18 -1520 l0
            -1470 -27 -68 c-14 -37 -39 -89 -54 -115 -42 -72 -160 -182 -234 -219 -139
            -67 -31 -63 -1535 -63 -1477 0 -1393 -3 -1515 52 -106 48 -266 206 -272 270
            l-3 23 820 5 820 5 64 27 c161 70 270 181 323 333 26 73 26 251 0 325 -33 95
            -61 140 -130 209 -76 74 -142 114 -240 142 -64 18 -108 19 -889 19 l-823 0 -5
            23 c-3 12 -5 463 -3 1002 l3 980 27 68 c40 102 75 155 147 227 95 94 202 146
            326 160 39 4 669 7 1400 6 l1330 -1 60 -22z m-1703 -2800 c58 -5 74 -11 108
            -40 107 -91 69 -265 -65 -301 -62 -16 -3724 -18 -3789 -1 -62 15 -108 57 -130
            118 -17 50 -17 52 0 102 25 68 82 116 144 122 76 6 3660 7 3732 0z m-1902
            -749 c11 -25 20 -50 20 -55 0 -16 56 -125 97 -187 59 -91 196 -222 294 -280
            48 -29 94 -52 102 -52 9 0 19 -5 22 -10 3 -6 25 -15 48 -21 83 -21 77 7 78
            -388 0 -193 -3 -356 -6 -361 -4 -6 -164 -10 -433 -11 -536 0 -595 -8 -722 -89
            -248 -158 -321 -484 -162 -728 19 -29 61 -77 95 -107 105 -95 211 -133 401
            -141 60 -3 113 -7 118 -10 13 -9 10 -318 -4 -332 -29 -29 -526 -5 -588 28 -10
            5 -25 10 -33 10 -16 0 -101 38 -162 73 -173 97 -317 268 -388 462 -54 148 -50
            65 -54 1165 -3 558 -2 1030 2 1047 l6 33 625 0 625 0 19 -46z m3310 -676 c3
            -215 -1 -672 -5 -679 -10 -15 -2268 -12 -2283 3 -9 9 -12 99 -12 343 0 183 3
            335 7 338 3 4 521 7 1150 7 908 0 1143 -3 1143 -12z m1318 -1068 c85 -48 120
            -156 76 -231 -11 -19 -39 -48 -63 -66 l-43 -33 -2430 0 -2431 0 -36 22 c-56
            35 -68 49 -86 106 -17 50 -17 55 0 100 26 69 59 99 135 123 14 4 1107 7 2430
            6 l2405 -2 43 -25z m-616 -702 c9 -15 11 -998 2 -1022 -6 -15 -178 -16 -1849
            -16 -1671 0 -1843 1 -1849 16 -3 9 -6 242 -6 519 0 427 2 505 14 512 8 4 839
            7 1847 6 1478 -2 1834 -5 1841 -15z"/>
            
                </g>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Tables & Chairs</h2>
              <p className="leading-relaxed text-base">
                At our establishment, we equip offices, libraries, and schools with premium quality tables and chairs. From the youngest of learners to the oldest, we cater to students of all levels with ergonomic and stylish furniture.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full mb-5 flex-shrink-0">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10"
                viewBox="0 0 900.000000 900.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M910 7979 c-193 -55 -333 -144 -450 -289 -68 -84 -113 -167 -148
            -276 -42 -129 -42 -120 -42 -2362 0 -2281 -1 -2232 44 -2375 73 -231 285 -446
            526 -531 126 -45 143 -46 1350 -46 630 0 1151 -4 1158 -9 10 -6 13 -66 13
            -271 0 -145 -4 -266 -8 -270 -4 -5 -48 -10 -98 -13 -49 -3 -108 -10 -129 -17
            -56 -17 -151 -114 -167 -171 -16 -55 -17 -132 -1 -185 16 -54 83 -129 143
            -159 l48 -25 1341 0 1341 0 47 24 c80 41 130 106 151 197 24 99 -13 198 -100
            273 -53 45 -90 57 -204 63 -49 3 -94 8 -98 13 -4 4 -8 125 -8 270 0 205 3 265
            13 271 7 5 528 9 1158 9 1207 0 1224 1 1350 46 116 41 229 113 320 204 134
            134 207 280 239 481 16 96 15 4351 0 4451 -37 234 -145 411 -345 566 -72 56
            -165 98 -289 132 -69 19 -139 20 -3580 19 -3386 -1 -3512 -2 -3575 -20z m7090
            -573 c60 -40 98 -81 123 -135 l22 -46 0 -1715 0 -1715 -3655 0 -3655 0 -3
            1702 c-2 1697 -2 1702 18 1754 12 29 30 65 41 81 26 36 103 89 143 99 17 4
            1582 6 3477 6 l3445 -2 44 -29z m138 -4188 c8 -8 12 -57 12 -157 0 -188 -13
            -234 -90 -311 -100 -101 312 -90 -3570 -90 -3882 0 -3469 -11 -3570 90 -77 77
            -90 123 -90 311 0 100 4 149 12 157 17 17 7279 17 7296 0z m-3097 -1128 c18
            -10 19 -24 19 -268 0 -187 -3 -261 -12 -270 -17 -17 -1099 -17 -1116 0 -9 9
            -12 83 -12 270 0 234 2 258 18 267 24 14 1076 15 1103 1z"/>
            <path d="M3821 7141 c-57 -23 -125 -90 -151 -148 -27 -61 -27 -162 1 -222 11
            -25 42 -68 69 -97 l50 -53 0 -463 c0 -473 -2 -496 -39 -566 -15 -26 -71 -151
            -71 -157 0 -2 -17 -42 -39 -87 -21 -46 -57 -128 -81 -182 -23 -54 -45 -106
            -50 -115 -8 -17 -22 -48 -65 -146 -36 -81 -57 -127 -94 -208 -17 -37 -31 -69
            -31 -72 0 -3 -14 -35 -31 -72 -16 -38 -37 -83 -45 -101 -8 -18 -14 -63 -14
            -101 0 -85 26 -148 84 -205 84 -81 11 -76 1176 -76 1165 0 1092 -5 1176 76 58
            57 84 120 84 205 0 38 -6 83 -14 101 -8 18 -29 63 -45 101 -17 37 -31 69 -31
            72 0 3 -14 35 -31 72 -36 77 -72 157 -101 223 -37 85 -50 115 -58 131 -5 9
            -27 61 -50 115 -24 54 -60 136 -81 182 -22 45 -39 85 -39 87 0 6 -56 131 -71
            157 -37 70 -39 93 -39 565 l0 463 51 56 c68 75 89 123 89 205 0 84 -25 143
            -85 201 -85 80 -51 77 -755 77 -576 0 -628 -2 -669 -18z m786 -547 l22 -6 3
            -527 3 -526 33 -70 c18 -38 39 -83 47 -100 7 -16 27 -60 44 -98 17 -37 31 -69
            31 -72 0 -2 17 -42 39 -87 21 -46 58 -128 81 -183 23 -55 45 -104 49 -110 20
            -28 72 -165 66 -174 -4 -8 -170 -11 -535 -11 -365 0 -531 3 -535 11 -6 9 46
            146 66 174 4 6 26 55 49 110 23 55 60 137 81 183 22 45 39 85 39 87 0 3 14 35
            31 72 17 38 37 82 44 98 8 17 29 62 47 100 l33 70 3 527 c3 486 4 527 20 531
            26 7 214 8 239 1z"/>
            
                </g>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Labs & Library</h2>
              <p className="leading-relaxed text-base">
                Elevate your learning and work environments with our top-of-the-line furniture designed for labs, libraries, schools, and offices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>



  <section className="bg-white">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <nav className="flex flex-wrap justify-center -mx-5 -my-2">
          <div className="px-5 py-2">
            <a href="/furniture" className="text-base leading-6 text-gray-500 hover:text-gray-900">
              Furniture
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="/safety" className="text-base leading-6 text-gray-500 hover:text-gray-900">
              Safety
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="/aboutus" className="text-base leading-6 text-gray-500 hover:text-gray-900">
              AboutUs
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="/contact" className="text-base leading-6 text-gray-500 hover:text-gray-900">
              Contact
            </a>
          </div>
        </nav>
        <div className="flex justify-center mt-8 space-x-6">
          <a href="#" className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Facebook</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path>
            </svg>
          </a>
          <a href="#" className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Instagram</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path>
                </svg>
            </a>
            <a href="#" class="text-gray-400 hover:text-gray-500">
                <span class="sr-only">Twitter</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                </svg>
            </a>
        </div>
        <p class="mt-8 text-base leading-6 text-center text-gray-400">
            © 2023 Shivangi Enterprises, Inc. All rights reserved.
        </p>
    </div>
    </section>



      </div>
    </div>
  );
}
           


export default Allinone
