import React from 'react'

import logo from '../../images/logo1.png'

function Nav() {
  return (
    <div className="container mx-auto flex flex-wrap p-2 flex-col md:flex-row items-center text-black body-font">
      <a className="flex title-font font-bold items-center text-black  md:mb-0">
        <img src={logo} alt="Shivangi Enterprises" width="190" height="190" />
      </a>
      <nav className="md:ml-auto flex flex-wrap items-center text-bold text-lg justify-center items-start">
        <a href="/" className="mr-5 hover:text-gray-900">Home</a>
        <a href="/safety" className="mr-5 hover:text-gray-900">Safety</a>
        <a href="/furniture" className="mr-5 hover:text-gray-900">Furniture</a>
        <a href="/aboutus" className="mr-5 hover:text-gray-900">About Us</a>
      </nav>
      <a href="/contact" className="inline-flex items-center bg-black text-white border-0 py-1 px-3 focus:outline-none hover:bg-indigo-700 rounded text-base mt-4 md:mt-0">Contact</a>
    </div>
  );
}


export default Nav