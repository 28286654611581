import React from 'react'

function Aboutfirst() {
  return (
    <>
    <div id='aboutfirst'>
    <section class="px-12  mt-5 text-gray-600 body-font overflow-hidden">

<div class="flex flex-col text-center w-full mb-20">
  <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-indigo-900">About Us</h1>
  <p class="lg:w-3/3 mx-auto leading-relaxed text-base">
  With 20 years of experience in safety devices and services we promise you best quality and experience. At Shivangi Enterprises offers both automatic fire detection and suppression services for commercial and residential properties, as well as innovative and functional school furniture design. With a commitment to superior quality, customer satisfaction, and a balance between performance and price, their highly qualified project team prioritizes quality, coordination, and timely execution for even the most complex and time-sensitive projects.
  </p>
  </div>

<div class="flex flex-col text-center w-full ">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-indigo-900">Safety Services</h1>
      <p class="lg:w-3/3 mx-auto leading-relaxed text-base">Shivangi Enterprises is a commercial and residential automatic fire detection and suppression contractor. For the success of any project, three vital aspects are: "Quality, Coordination and time schedule". At Shivangi Enterprises, these services are executed by highly and qualified and efficient project team who with their in-depth experience can take up any sophisticated and time bound project in the most competitive manner. </p>
</div>

  <div class="container px-5 py-10 mx-auto">
    <div class="-my-8 divide-y-2 divide-gray-100">
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-indigo-900">Design</span>
        </div>
        <div class="md:flex-grow">
          <p class="leading-relaxed">We design, fabricate, and install complete fire systems from Detectors sprinklers to fire pumps.</p>

        </div>
      </div>
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-indigo-900">Installation</span>
        </div>
        <div class="md:flex-grow">
          <p class="leading-relaxed">We provide, install and service full line of fire detection and fire fighting equipment's. We have licensed engineers, service and installation technician.</p>

        </div>
      </div>
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-indigo-900">Maintenance</span>
        </div>
        <div class="md:flex-grow">
          <p class="leading-relaxed">	Every Shivangi Enterprises fire prevention system is designed, built and installed to work perfectly when lives and property are at stake. We provide maintenance</p>

        </div>
      </div>
    </div>
  </div>
</section>



</div>
        
    </>
  )
}

export default Aboutfirst