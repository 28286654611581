import React from 'react'
import Allinone from './Allinone'


function Home() {
  return (
    <div className='home'>
      
      <Allinone/>
     
      
    </div>
  )
}

export default Home