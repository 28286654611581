import React from 'react'
import Aboutfirst from './Aboutfirst'




function Aboutus() {
  return (
    <div className='About'>
      <Aboutfirst/>
    
      
    </div>
  )
}

export default Aboutus