// import logo from './logo.svg';
import './App.css';
import Nav from './components/Homepage/Nav';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Homepage/Home';
import Aboutus from './components/Aboutus/Aboutus';
import Conn from './components/Contacts/Contact.js';
import Furniture from './components/Furniture/Furniture.js';
import Safety from './components/Safety/Safety.js';


function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Nav />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/aboutus' element={<Aboutus/>} />
            <Route path='/contact' element={<Conn/>} />
            <Route path='/furniture' element={<Furniture/>} />
            <Route path='/safety' element={<Safety/>} />
            
            
          </Routes>
          
        </div>
      </Router>
    </>
  );
}

export default App;
