import React from 'react'
import one from '../../images/1 (1).jpg'
import two from '../../images/2 (1).jpg'  
import three from '../../images/3 (1).jpg' 
import four from '../../images/4(1).jpeg' 
import five from '../../images/5 (1).jpg' 
import six from '../../images/6 (1).jpg' 
import seven from '../../images/7 (1).jpg'
import eight from '../../images/8 (1).jpg'   
import eighteen from '../../images/18(1).jpeg'
import thirteen from '../../images/13 (1).jpg'
import fourteen from '../../images/14 (1).jpg'
import seventeen from '../../images/17 (1).jpg' 


function Furniture() {
  return (
 
<div className='contain'>

<section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-wrap -m-4">
          <div className="p-14 md:w-1/3">
          <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
          <img class="lg:h-64 md:h-36 object-cover object-center" src={one} alt="blog"></img>
          <div class="p-6">
            <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Double Headed Hydrant Valve</h1>
            <p class="leading-relaxed mb-3">Fire Hydrant system is an effective and efficient means of extinguishing large fires, which can otherwise cause devastation.</p>
            
          </div>
        </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={two} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Fire Hose Reel</h1>
                  <p className="leading-relaxed mb-3">Fire hose reel is a device that is used to store and dispense a fire hose.</p>
                </div>
              </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={three} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Automatic sprinkler</h1>
                  <p className="leading-relaxed mb-3">The automatic sprinkler and fire alarm system shall be designed to extinguish a fire in its early stages by the use of water.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-right" src={four} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">System Sensor WFD20</h1>
                  <p className="leading-relaxed mb-3">It is typically installed in a water supply pipe and is used to monitor the flow of water in the pipe.</p>
                </div>
              </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={five} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Fire Water Monitor</h1>
                  <p className="leading-relaxed mb-3">Fire water monitor, also known as a water cannon, is a device that is used in fire protection systems to deliver large amounts of water to a fire.</p>
                </div>
              </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={six} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Water Spray Nozzle</h1>
                  <p className="leading-relaxed mb-3">Medium velocity water spray system is a fixed pipe array designed to deluge water through a deluge valve onto special risks such as power generation equipment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={seven} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Deluge Valve</h1>
                  <p className="leading-relaxed mb-3">It is designed to provide a rapid and effective means of delivering large amounts of water to an area in the event of a fire.</p>
                </div>
              </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-right" src={eight} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Fire Fighting Water Monitor</h1>
                  <p className="leading-relaxed mb-3">It is a high-capacity water delivery system that is used in fire fighting operations to deliver large amounts of water to extinguish fires.</p>
                </div>
              </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={eighteen} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Photoelectric Smoke Thermal Detector</h1>
                  <p className="leading-relaxed mb-3">It is a type of smoke detector that uses both photoelectric and thermal sensors to detect the presence of smoke and heat in a building.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={thirteen} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Fire Extinguisher</h1>
                  <p className="leading-relaxed mb-3">A fire extinguisher is a portable device that is used to extinguish small fires or to control them until the fire department arrives.</p>
                </div>
              </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={fourteen} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">White Addressable Morley</h1>
                  <p className="leading-relaxed mb-3">It is a type of fire alarm device that is commonly used in fire protection systems.</p>
                </div>
              </div>
            </div>
            <div className="p-14 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-64 md:h-36 object-cover object-top" src={seventeen} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">CO2 Fire Extinguisher</h1>
                  <p className="leading-relaxed mb-3">Carbon dioxide fire extinguishers are used for fighting fires involving electrical equipment and flammable liquids.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>    
</div>




)
}

export default Furniture